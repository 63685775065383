import {extendTheme} from "@chakra-ui/react";

export const colors = {
    aemGreen: {
        100: "rgb(120,167,74)",
        500: "rgb(85,137,68)",
        900: "rgb(51,110,61)",
    },
    aemBrown: {
        500: "rgb(211,110,90)"
    }
};

export const theme = extendTheme({colors});