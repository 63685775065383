type RatingDescriptions = [string, string, string, string]

export type SkillDescriptor = {
    order: number,
    name: string,
    ratingDescriptions: RatingDescriptions
}

export const SkillDescriptors: {[category: string]: SkillDescriptor} = {
    'safety': {
        order: 1,
        name: 'Segurança e técnicas de cordas',
        ratingDescriptions: [
            'Equipa-se e encorda-se com necessidade de verificação',
            'Equipa-se e encorda-se. Dá segurança em tope-rope sob vigilância. (Faz rapel com segurança por baixo)',
            'Equipa-se e encorda-se autónomo, dá segurança em top-rope autónomo e escala e dá segurança, de primeiro, sob vigilância.',
            'Escala e dá segurança de primeiro autónomo, “Mosquetoneia” rápido e correto, coloca-se bem em relação à corda, cai controlado e em segurança. detém quedas com dinamismo. Desmonta vias. Permanece atento e reativo a todos os aspetos'
        ]
    },
    'reading': {
        order: 2,
        name: 'Leitura / Visualização',
        ratingDescriptions: [
            'Não olha para a via antes de escalar. Vê apenas presas acima e à frente dos olhos. Apoia os pés ao acaso.',
            'Observa fugazmente a linha antes de escalar. Vê presas predominantemente no plano superior, sem grande critério e apercebendo-se de algumas presas de pés.',
            'Tenta interpretar a linha desde o solo e definir uma trajetória, escolhendo e memorizando presas de referência. Planeia algumas sequências de movimento.',
            'Visualiza, lê e antecipa os movimentos antes e durante a escalada. Identifica grande diversidade as presas, inclusive as mais improváveis e tenta tirar partido delas. Planeia sequências chave de movimento com eficiência. Antecipa várias soluções para cada passagem.'
        ]
    },
    'feet': {
        order: 3,
        name: 'Técnica de pés',
        ratingDescriptions: [
            'Apoia os pés lateralmente, apenas em apoios grandes. Pés arrastam na parede e acertam no apoio por tentativa e erro.',
            'Apoia os pés frontalmente em apoios mais variados. Ainda pontapeia a parede e necessita de 3 a 4 ajustes para colocar o pé.',
            'Observa a presa para escolher a superfície de apoio, com predomínio da ponta. Roda e troca os pés sobre as presas. Ainda faz ajustes à posição inicial de apoio. Usa o calcanhar pontualmente',
            'Apoia os pés meticulosamente e à primeira, tirando proveito de uma grande variedade de técnicas (gancheio, calcanhar, aderência, oposição, entalamento) Utiliza apoios afastados, variados e minimais.'
        ]
    },
    'hands': {
        order: 4,
        name: 'Técnica de mãos',
        ratingDescriptions: [
            'Utiliza apenas grandes presas de tração acima do nível da cabeça. Braços em flexão e mãos apoiadas sempre acima da cabeça',
            'Recorre a presas mais pequenas e variadas, ainda que de passagem. Abre o leque de presas úteis ao perímetro superior de alcance dos braços',
            'Utiliza presas num maior raio de ação e tenta adaptar a posição da mão ao formato da presa. Tira partido de várias técnicas e formatos de presas (pinças, laterais, monos)',
            'Escolhe meticulosamente e ajusta os dedos à presa. Exerce menos esforço nas presas cómodas, relaxa o braço que não está ser utilizado. Tem grande capacidade de preensão, bloqueio, pinçamento, aderência, mantel e regletes.'
        ]
    },
    'body': {
        order: 5,
        name: 'Técnica corporal',
        ratingDescriptions: [
            'Permanece tenso e frontal à parede, com braços e pernas semi fletidos',
            'Afasta a anca da parede para observar os apoios. Escala com menos tensão, mas maioritariamente frontal.',
            'Roda frequentemente o corpo as cinturas escapular e pélvica e varia a postura de modo, relaxa quando possível e procura posições de repouso.',
            'Adapta a postura do corpo ao plano de modo a tirar rendimento das presas e encontrar posições de equilíbrio e menor esforço. Faz transferências de peso, cruzamentos, lolotes, bandeiras, yaniro…'
        ]
    },
    'propulsion': {
        order: 6,
        name: 'Propulsão',
        ratingDescriptions: [
            'Progride com base na tração dos braços realizando movimentos dinâmicos de mãos e pés',
            'Progride na vertical com predomínio da tração procurando sempre alcançar a próxima presa de mãos.',
            'Progride com a ajuda do trem inferior, sobe os pés, antes das mãos, e a permanece com as mãos ao nível dos ombros. Progride em direções variadas.',
            'Progride frequentemente sobre 2 apoios, de forma dinâmica, fluida, equilibrada e eficiente. Realiza trajetórias variadíssimas com diagonais, travessias e destrepes.'
        ]
    },
    'balance': {
        order: 7,
        name: 'Fluidez / Equilíbrio',
        ratingDescriptions: [
            'Progressão quase estática, não escolhe o membro que vai mexer. Pouco equilíbrio.',
            'Alterna peso e respeita regra dos 3 apoios. Liberta peso da mão que vai mexer.',
            'Progressão fluida e encadeada, com transferências de peso constantes. Transmite alguma supless, leveza, e controlo dos desequilíbrios.',
            'Recupera equilíbrio após cada movimento ou sequência. Progride com supless e movimentos económicos, ritmados, graciosos, aproveitando os equilíbrios e desequilíbrios.'
        ]
    },
    'rhythm': {
        order: 8,
        name: 'Ritmo e Tática',
        ratingDescriptions: [
            'Não existem',
            'Progressão descontinuada.',
            'Fluído, parando nas posições de menor esforço e mais equilíbrio.',
            'Recupera e após cada sequência. Alterna sequências rápidas nas secções difíceis com posições de repouso.'
        ]
    }
}

export type SkillCategory = keyof typeof SkillDescriptors

export type SkillRating = 1 | 2 | 3 | 4;

export type Skill = {
    category: SkillCategory,
    rating: SkillRating
}

export const EMPTY_SKILLS: Skill[] = Object.keys(SkillDescriptors)
    .map(cat => ({category: cat, rating: 1}))

export const SORTED_SKILL_CATEGORIES = Object.entries(SkillDescriptors)
    .sort((s1, s2) => s1[1].order - s2[1].order)
    .map(s => s[0]);