import image1 from "../images/jp-galayos.jpeg";
import image2 from "../images/eduardo-oleiros.jpeg";
import image3 from "../images/desconhecido.jpg";
import image4 from "../images/mariana.jpg";
import image5 from "../images/material.jpg";
import image6 from "../images/tecnico.jpeg";
import {useHookstate, useHookstateEffect} from "@hookstate/core";
import {Box, Container, Image} from "@chakra-ui/react";
import {ReactNode} from "react";

export function ImageBox({children}: { children: ReactNode }) {
    const images = [image1, image2, image3, image4, image5, image6]
    const currentImage = useHookstate(Math.floor(Math.random() * (images.length)))
    // const currentImage = useHookstate(2)

    useHookstateEffect(() => {
        const intervalId = window.setInterval(() => {
            if (currentImage.value >= images.length - 1) {
                currentImage.set(0)
            } else {
                currentImage.set(ci => ci + 1)
            }
        }, 30000)

        return () => window.clearInterval(intervalId)
    })

    return (
        <Container height={'calc(100vh - 5.5em)'} maxW={'8xl'} padding={0}>
            <Box
                pos={'relative'}
                height={'100%'}
                width={'100%'}
            >

                <Box
                    objectFit={'cover'}
                    backgroundImage={`url(${images[currentImage.value]})`}
                    backgroundSize={'cover'}
                    backgroundPosition={'center top'}
                    height={'100%'}
                    width={'100%'}
                />

                <Box
                    pos={'absolute'}
                    top={'0px'}
                    left={'0px'}
                    padding={5}
                    width={'100%'}
                    minHeight={'100%'}
                >
                    {children}
                </Box>
            </Box>
        </Container>
    );
}