import React from 'react';
import './App.css';
import {ChakraProvider} from "@chakra-ui/react";
import {RouterProvider} from "react-router-dom";
import {router} from "./Router";
import {initAuth} from "./utils/auth";
import {theme} from "./theme";
import {initDb} from "./utils/firestore";

initAuth();
initDb();

function App() {

    return (
        <ChakraProvider theme={theme}>
            <RouterProvider router={router}/>
        </ChakraProvider>
    );
}

export default App;
