import {useHookstate, useHookstateEffect} from "@hookstate/core";
import {Skill, SkillDescriptors, SkillRating, SORTED_SKILL_CATEGORIES} from "../models/skills";
import {Button, Center, Container, Flex, Text} from "@chakra-ui/react";
import {Radar} from "react-chartjs-2";
import Color from "color";
import {Chart as ChartJS, ChartData, Filler, Legend, LineElement, PointElement, RadialLinearScale} from "chart.js";
import {useEvaluations} from "../database/evaluations";
import {colors} from "../theme";
import {ChartDataset} from "chart.js/dist/types";
import {globalsState, useTitle} from "../utils/global";
import {globalAuthState} from "../utils/auth";
import {ImageBox} from "../components/ImageBox";
import {useNavigate} from "react-router-dom";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Legend);

const skillsCategoriesAndNames = SORTED_SKILL_CATEGORIES
    .map(c => [c, SkillDescriptors[c].name]);

export function Profile() {

    const navigate = useNavigate()
    const setTitle = useTitle('Perfil Escalada AEM');

    const startEditing = () => {
        navigate('/assessment')
    }

    const fullyLoaded = useHookstate(false)

    const evaluations = useEvaluations((evaluations) => {
        if (!evaluations || !evaluations.history.length) {
            window.setTimeout(startEditing, 1)
        }
        if (!fullyLoaded.value) {
            fullyLoaded.set(true)
            showNameOnTitle()
        }
    }).history;

    const skills = evaluations.length > 0 ?
        evaluations[evaluations.length - 1].skills :
        null;


    const pastSkills = evaluations.length > 1 ?
        evaluations[evaluations.length - 2].skills :
        null;

    const loginHint = useHookstate(globalsState).loginHint
    const auth = useHookstate(globalAuthState)

    function showNameOnTitle() {
        if (auth.name.value) {
            setTitle(`${auth.name.value} - Escalada AEM`)
        } else {
            setTitle('Escalada AEM')
        }
    }

    useHookstateEffect(showNameOnTitle, [auth])

    const saveSkills = (newSkills: Skill[]) => {

        if (!evaluations.length && auth.state.value === "anonymous") {
            loginHint.set('Faz login para guardares a avaliação permanentemente!')
        }
    }

    const reset = () => {
        evaluations.set([]);
    }

    const colorDataSet = (color: Color, dataset: ChartDataset<"radar", SkillRating[]>) => ({
        borderColor: color.alpha(0.2).rgb().string(),
        borderWidth: 5,
        backgroundColor: color.alpha(0.5).lighten(0.5).rgb().string(),
        tension: 0.1,
        ...dataset
    })

    const mkRadarData = () => {
        const datasets: ChartDataset<"radar", SkillRating[]>[] = []

        if (skills) {
            datasets.push(colorDataSet(Color(colors.aemGreen[900]), {
                label: "Avaliação",
                data: skillsCategoriesAndNames.map(sc =>
                    skills.value?.find(s => s.category === sc[0])?.rating || 1)
            }));
        }

        if (pastSkills) {
            datasets.push(colorDataSet(Color(colors.aemGreen[100]).lighten(0.3), {
                label: "Avaliação anterior",
                data: skillsCategoriesAndNames.map(sc => pastSkills.value
                    ?.find(s => s.category === sc[0])?.rating || 1),
            }));
        }
        return {
            labels: skillsCategoriesAndNames.map(sc => sc[1]),
            datasets
        } as ChartData<"radar", SkillRating[]>;
    }

    return (
        <ImageBox>
            {fullyLoaded.value &&
                <Container
                    padding={5}
                    backgroundColor={Color('white').alpha(0.95).rgb().string()}
                    borderRadius={2}
                >
                    <Flex direction={"column"}>
                        <Center>
                            {skills
                                ? <Radar
                                    data={mkRadarData()}
                                    options={{
                                        backgroundColor: Color(colors.aemGreen[100]).hex(),
                                        scales: {
                                            r: {
                                                min: 0,
                                                max: 4,
                                                grid: {
                                                    circular: true,
                                                    color: Color('gray').lighten(0.90).hex()
                                                    // display: false
                                                },
                                                ticks: {
                                                    display: false
                                                },
                                                pointLabels: {}
                                            }
                                        },
                                        plugins: {
                                            filler: {
                                                drawTime: 'beforeDatasetsDraw'
                                            },
                                            legend: {
                                                display: true
                                            }
                                        }
                                    }}
                                />
                                : <Text>Ainda não criaste a tua avaliação.</Text>
                            }
                        </Center>
                        <Center>
                            <Button onClick={startEditing}>Fazer Avaliação</Button>
                        </Center>
                        {/*{skills && <Center>*/}
                        {/*    <Button variant={"link"} onClick={reset}>Limpar tudo</Button>*/}
                        {/*</Center>}*/}
                    </Flex>
                </Container>
            }
        </ImageBox>

    );
}