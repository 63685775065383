import {getAuth, GoogleAuthProvider, signInWithPopup, signOut} from "firebase/auth";
import {app} from "../utils/firebase";
import {Button, Center, Container, Spinner} from "@chakra-ui/react";
import {GoogleSigninButton} from "../components/GoogleSigninButton";
import {useNavigate} from "react-router-dom";
import {useHookstate} from "@hookstate/core";
import {globalAuthState, returnToState} from "../utils/auth";
import {ImageBox} from "../components/ImageBox";
import {useTitle} from "../utils/global";

const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

export function Login() {

    const authState = useHookstate(globalAuthState.state);
    const navigate = useNavigate()
    useTitle('Login AEM')


    const loginWithGoogle = () => {
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                navigate(returnToState.value.toString())
            })
            .catch((error) => {
                navigate(returnToState.value.toString())
            });
    };

    const logout = () => {
        signOut(auth)
            .then(() => navigate('/'))
            .catch(() => navigate('/'))
    }

    const Content = () => {
        switch (authState.value) {
            case "pending":
                return <Spinner/>;
            case "no-auth":
            case "anonymous":
                return <GoogleSigninButton onClick={loginWithGoogle}/>
            case "auth":
                return <Button onClick={logout}>Logout</Button>

        }
    }
    return (
        <ImageBox>
            <Center>
                <Content/>
            </Center>
        </ImageBox>
    );
}