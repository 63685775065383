import {hookstate, State, useHookstate, useHookstateEffect} from "@hookstate/core";
import {Evaluations} from "../models/evaluations";
import {globalAuthState} from "../utils/auth";
import {firestoreDoc} from "./hsfs";
import {cloneState} from "../utils/utils";


export const temporaryEvaluations = hookstate<Evaluations | undefined>(undefined)

export function useEvaluations(onChange?: ((evaluations: Evaluations) => void)): State<Evaluations> {

    const authState = useHookstate(globalAuthState);

    const wrappedOnChange = (evaluations: Evaluations) => {

        if (evaluations.history.length && authState.state.value === 'anonymous') {
            temporaryEvaluations.set(evaluations);
        } else if (authState.state.value === 'auth' && temporaryEvaluations.value) {
            const toMerge = cloneState<Evaluations>(temporaryEvaluations).history
            temporaryEvaluations.set(undefined)
            state.history.merge(toMerge)
        }


        // onChange(evaluations)
    }

    const extensions = authState.uid.value ?
        firestoreDoc('evaluations', [authState.uid.value], onChange) : undefined;

    const state = useHookstate<Evaluations>(
        {history: []} as Evaluations,
        extensions
    );

    return state;
}
