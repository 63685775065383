import {ExtensionFactory, State} from "@hookstate/core";
import {doc, getFirestore, onSnapshot, setDoc} from "firebase/firestore";
import {cloneState} from "../utils/utils";

const db = getFirestore();

export function firestoreDoc<S, E>(collection: string, path: string[], onChange?: ((evaluations: S) => void)): ExtensionFactory<S, E, any> {

    let docRef = doc(db, collection, ...path);

    return () => {
        let stateAtRoot: State<S, E>;
        let unsubscribe: () => void;

        function invokeOnChange() {
            if (onChange) {
                onChange(cloneState(stateAtRoot))
            }
        }

        return {
            onInit: (state, extensionMethods) => {
                stateAtRoot = state;
                unsubscribe = onSnapshot(docRef, snp => {
                    if (state && snp.exists()) {
                        state.set(snp.data())
                    }
                    invokeOnChange();
                })
            },
            onSet: (state, actionDescriptor) => {
                if (stateAtRoot.value) {
                    const clonedState: S = cloneState(stateAtRoot);

                    // @ts-ignore
                    setDoc(docRef, clonedState);
                    invokeOnChange();
                }
            },
            onDestroy: (state) => {
                if (unsubscribe) {
                    unsubscribe();
                }
            }
        }
    }
}