import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const db = getFirestore();

if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, '127.0.0.1', 8080);
}

export function initDb() {
    // nothing
}