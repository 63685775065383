import {Box, Button, Center, Flex, Slide} from "@chakra-ui/react";
import {colors} from "../theme";
import {ImageBox} from "../components/ImageBox";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../utils/global";

export function Home() {
    const navigate = useNavigate()
    useTitle('Ferramentas AEM')
    return (
        <ImageBox>
            {/*<Slide direction='left' in={true} style={{zIndex: 10}}>*/}
                <Box
                    position={'absolute'}
                    top={'50%'}
                    left={'50%'}
                    transform="translate(-50%, 50%)"
                >
                    <Flex>
                        <Center>
                            <Button
                                backgroundColor={colors.aemGreen[100]}
                                boxShadow={'dark-lg'}
                                borderColor={colors.aemBrown[500]}
                                borderWidth={4}
                                size={'lg'}
                                onClick={() => navigate('/profile')}
                            >
                                Avaliação Escalada
                            </Button>
                        </Center>
                    </Flex>
                </Box>
            {/*</Slide>*/}
        </ImageBox>
    );
}