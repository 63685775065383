import {hookstate, useHookstate, useHookstateEffect} from "@hookstate/core";

export type Globals = {
    loginHint?: string,
    pageTitle: string
}

export const globalsState = hookstate<Globals>({
    pageTitle: 'AEM'
});

export function useTitle(title: string) {
    const pageTitle = useHookstate(globalsState).pageTitle

    const updateTitle = (newTitle: string) => {
        pageTitle.set(newTitle)
        document.title = newTitle
    }

    useHookstateEffect(() => {
        updateTitle(title)
    }, [title])

    return updateTitle
}