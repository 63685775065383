import {Box, Container} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import {Header} from "../components/Header";

export function Root() {
    return (
        <Container maxW={'8xl'} padding={0}>
            <Header/>
            <Outlet/>
        </Container>
    );
}