// Import the functions you need from the SDKs you need

import {initializeApp} from "firebase/app";

import {getAnalytics} from "firebase/analytics";

import { getAuth, connectAuthEmulator } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    apiKey: "AIzaSyAI5ety3MDpQ_Q8FdXD64MTIA1TuaShTJo",

    authDomain: "perfil.escolademontanha.com",

    projectId: "escola-de-montanha",

    storageBucket: "escola-de-montanha.appspot.com",

    messagingSenderId: "1016471960147",

    appId: "1:1016471960147:web:9c40db213b434838aaa4a6",

    measurementId: "G-3T9759SGB2"

};


// Initialize Firebase

export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

