import {createBrowserRouter} from "react-router-dom";
import {Profile} from "./routes/Profile";
import {Login} from "./routes/Login";
import {Root} from "./routes/Root";
import {Home} from "./routes/Home";
import {SkillAssessment} from "./routes/SkillAssessment";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/profile",
                element: <Profile />
            },
            {
                path: "/assessment",
                element: <SkillAssessment />
            },
            {
                path: "/login",
                element: <Login />
            }
        ]
    }
]);