import {
    Avatar,
    Box,
    Flex,
    Heading,
    Image,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader, PopoverTrigger,
    Spinner
} from "@chakra-ui/react";
import {useHookstate} from "@hookstate/core";
import {globalAuthState, returnToState} from "../../utils/auth";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {globalsState} from "../../utils/global";

export function Header() {
    const auth = useHookstate(globalAuthState);
    const loginHint = useHookstate(globalsState).loginHint;
    const title = useHookstate(globalsState).pageTitle;
    const hintsShown = useHookstate<string[]>([]);
    const open = useHookstate(false)
    const navigate = useNavigate();
    const location = useLocation();

    const toggleOpen = () => open.set(o => !o)

    const toLogin = () => {
        returnToState.set(location.pathname);
        navigate('/login')
    }

    const closeHint = () => {
        hintsShown[hintsShown.length].set(loginHint.value || '')
    }

    const AuthIcon = () => {
        switch (auth.state.value) {
            case "no-auth":
            case "anonymous":
                const img = <Image src={'/login-32.png'} h={'2em'} onClick={toLogin} className={'.login-button'} />

                if (!loginHint.value || (hintsShown.value.indexOf(loginHint.value || '') >= 0)) {
                    return img
                }

                return <Popover
                    isOpen={true}
                    onClose={closeHint}
                >
                    <PopoverTrigger>{img}</PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton color={'black'} />
                        <PopoverHeader color={'black'} fontWeight={'bold'}>Dica</PopoverHeader>
                        <PopoverBody color={'black'}>{loginHint.value}</PopoverBody>
                    </PopoverContent>
                </Popover>
            case "pending":
                return <Spinner color={'aemGreen.900'} />
            default:
                const avatarUrl = auth.avatar.value || '/default-profile.png'
                return <Avatar maxW={'2em'} maxH={'2em'} src={avatarUrl} onClick={toLogin} />
        }
    }

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding={6}
            margin={0}
            bg="aemGreen.100"
            color="white"
            height={'5.5em'}
        >
            <Flex align="center" mr={5}>
                <Box marginRight={'1em'}>
                    <Image height={'2em'} src={"/logo.png"} alt={'Logo AEM'} />
                </Box>
                <Heading as="h1" size="lg" letterSpacing={"tighter"}>
                    <Link to={'/'}>{title.value}</Link>
                </Heading>
            </Flex>

            <Box display={'block'} onClick={toggleOpen}>
                <AuthIcon />
            </Box>
        </Flex>
    );
}