import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Center,
    Container,
    Flex,
    ListItem,
    Radio,
    RadioGroup,
    Spacer,
    Stack,
    StackDivider,
    Text,
    UnorderedList
} from "@chakra-ui/react";
import {useHookstate} from "@hookstate/core";
import {Skill, SkillCategory, SkillDescriptors, SkillRating, SORTED_SKILL_CATEGORIES} from "../models/skills";
import {CheckIcon} from "@chakra-ui/icons";
import {ContentContainer} from "../components/ContentContainer";
import {useEvaluations} from "../database/evaluations";
import {useNavigate} from "react-router-dom";
import {useTitle} from "../utils/global";

export function SkillAssessment() {
    const inIntro = useHookstate(true);
    const skills = useHookstate([] as Skill[]);

    const evaluations = useEvaluations().history;
    const navigate = useNavigate()
    useTitle('Questionário Escalada')

    const setSkillRating = (category: SkillCategory, rating: SkillRating) => {
        const existingIndex = skills.findIndex(skill => skill.category.value === category)

        if (existingIndex >= 0) {
            skills[existingIndex].rating.set(rating);
        } else {
            skills.merge([{category, rating}])
        }
    }

    const getSkillRatingAsRadioValue = (category: SkillCategory) => {
        const rating = skills.find(skill => skill.category.value === category)?.rating.value;
        return rating ? '' + rating : undefined;
    }

    const isSet = (category: SkillCategory) => {
        return skills.find(skill => skill.category.value === category)?.value !== undefined
    }

    const cancel = () => navigate('/profile')

    const save = (newSkills: Skill[]) => {
        evaluations[evaluations.length].set({
            date: new Date(),
            skills: newSkills
        })
        navigate('/profile')
    }

    if (inIntro.value) {
        return <ContentContainer>
            <Text marginBottom={2}>
                Este questionário pretende ser uma ferramenta de autoavaliação de competências técnicas de escalada.
                Responder de forma consciente e verdadeira ajuda a tomar consciência dos seus pontos fortes, assim como,
                de aspetos técnicos que carecem de mais foco e aprimoramento.
                Permite tomar consciência de conceitos que desconhece e poderá dar alguma orientação para treinar e para
                solicitar ajuda externa.
            </Text>
            <Text as={'b'}>Dica para o preenchimento:</Text>
            <UnorderedList marginBottom={2}>
                <ListItem>Se for iniciado deve ler os campos do mais elementar para o mais
                    avançado.</ListItem>
                <ListItem>Se encontrar linguagem ou conceitos que não compreende, provavelmente não domina
                    este
                    estádio evolutivo, devendo voltar ao anterior.</ListItem>
                <ListItem>Se é um praticante experiente, será boa ideia começar por ler os estádios mais
                    avançados, evitando ler muita informação desnecessária.</ListItem>
                <ListItem>Deverá reavaliar-se a cada 6 meses.</ListItem>
            </UnorderedList>
            <Text marginBottom={2}>
                Em caso de dúvida no preenchimento pode contactar a equipa técnica de Associação Escola de
                Montanha, para ter orientação e aconselhamento técnico: <a href={"mailto:aemontanha@gmail.com"} target="_blank">aemontanha@gmail.com</a>
            </Text>
            <Text marginBottom={2}>
                Desejamos um bom preenchimento e que o resultado lhe seja útil.
            </Text>
            <Center>
                <Button onClick={() => inIntro.set(false)}>Continuar</Button>
            </Center>
        </ContentContainer>
    }

    return (
        <Container padding={5}>
            <Accordion defaultIndex={[0]}>
                {
                    SORTED_SKILL_CATEGORIES
                        .map(category => ({category, ...SkillDescriptors[category]}))
                        .map(descriptor =>
                            <AccordionItem key={descriptor.category}>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex='1' textAlign='left'>
                                            {isSet(descriptor.category) && <CheckIcon mr={1} color={'green'}/>}
                                            {descriptor.name}
                                        </Box>
                                        <AccordionIcon/>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <RadioGroup defaultValue={getSkillRatingAsRadioValue(descriptor.category)}
                                                onChange={value => setSkillRating(descriptor.category, parseInt(value) as SkillRating)}
                                    >
                                        <Stack divider={<StackDivider/>} spacing='4'>
                                            {
                                                descriptor.ratingDescriptions.map((description, index) =>
                                                    <Box key={index}>
                                                        <Radio value={'' + (index + 1)}>
                                                            {description}
                                                        </Radio>
                                                    </Box>
                                                )
                                            }
                                        </Stack>
                                    </RadioGroup>
                                </AccordionPanel>
                            </AccordionItem>
                        )
                }
            </Accordion>
            <Flex gap={2} pt={2}>
                <Spacer/>
                <Button variant='link' onClick={cancel}>
                    Cancelar
                </Button>
                <Button colorScheme='teal' size='md'
                        onClick={() => save(skills.get({noproxy: true}) as Skill[])}
                        isDisabled={skills.length !== SORTED_SKILL_CATEGORIES.length}>
                    Gravar
                </Button>
            </Flex>
        </Container>

    );
}