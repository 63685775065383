import {connectAuthEmulator, getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import {app} from "./firebase";
import {hookstate} from "@hookstate/core";

const auth = getAuth(app);
auth.useDeviceLanguage();
if (process.env.NODE_ENV === 'development') {
    console.log('Using auth emulator');
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export type AuthState = {
    state: 'pending' | 'no-auth' | 'anonymous' | 'auth',
    uid?: string,
    name?: string,
    email?: string,
    avatar?: string,
    rawRecord?: any
}

export const globalAuthState = hookstate<AuthState>({
    state: 'pending'
})

export const returnToState = hookstate<String>('/')

export function initAuth() {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            globalAuthState.set({
                state: user.isAnonymous ? 'anonymous' : 'auth',
                uid: user.uid,
                name: user.displayName || undefined,
                email: user.email || undefined,
                rawRecord: user,
                avatar: user.photoURL || undefined
            })
        } else {
            globalAuthState.set({
                state: 'pending'
            })
            signInAnonymously(auth)
                .catch(() => {
                    globalAuthState.set({
                        state: 'no-auth'
                    });
                });
        }
    });
}
